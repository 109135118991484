import React from "react";
// import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero1 from '../../images/main-slider/image-1.jpg'
import ptn from '../../images/main-slider/pattern-2.png'
import ptn2 from '../../images/main-slider/pattern-1.png'




const Hero = ({tournament}) => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
    };


    return (
        <section className="slider-one" id="home">
            <div className="slider-one_pattern-two" style={{ backgroundImage: `url(${ptn})` }}></div>
            <div className="main-slider-carousel">
                <Slider {...settings}>
                    {/*  Slide 01 */}
                    <div className="slider-one_slide">
                        <div className="slider-one_image-layer" style={{ backgroundImage: `url(${hero1})` }}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <div className="slider-one_title">Set Roulette Summer is here!</div>
                                    <div className="slider-one_date"><span>{tournament.eventDate.date}</span> {tournament.eventDate.month} <br /> {tournament.eventDate.year}</div>
                                    <h1 className="slider-one_heading">Join us for the Set Roulette Summer season</h1>
                                    <div className="slider-one_buttons">
                                        <a href={`https://melee.gg/Tournament/View/${tournament.id}`} target="new" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Sign Up for the Event</span>
                                                <span className="text-two">Sign Up for the Event</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/*  End Slide 01 */}

                    {/*  Slide 02 */}
                    <div className="slider-one_slide">
                        <div className="slider-one_image-layer" style={{ backgroundImage: `url(${hero1})` }}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <div className="slider-one_title">Find out what sets are legal</div>
                                    <div className="slider-one_date"><span>{tournament.revealDate.date}</span> {tournament.revealDate.month} <br /> {tournament.revealDate.year}</div>
                                    <h1 className="slider-one_heading">Live Stream <br /> the Set Reveal</h1>
                                    <div className="slider-one_buttons">
                                    <a href="https://discord.com/invite/uC7bV9eFkm" target="new" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Join us on Discord</span>
                                                <span className="text-two">Join us on Discord</span>
                                            </span>
                                    </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/*  End Slide 02 */}


                </Slider>

            </div>
        </section>
    )
}



export default Hero;