// import setUnknown from '../images/resource/set-unknown.jpg'
import setUnknown from '../images/resource/set-unknown.jpg';
import setMIR from '../images/resource/set-MIR.jpg';
import setVIS from '../images/resource/set-VIS.jpg';
import setWTH from '../images/resource/set-WTH.jpg';
import setTMP from '../images/resource/set-TMP.jpg';
import setSTH from '../images/resource/set-STH.jpg';
import setEXO from '../images/resource/set-EXO.jpg';
import setUSG from '../images/resource/set-USG.jpg';
import setULG from '../images/resource/set-ULG.jpg';
import setUDS from '../images/resource/set-UDS.jpg';
import setMMQ from '../images/resource/set-MMQ.jpg';
import setNEM from '../images/resource/set-NEM.jpg';
import setPCY from '../images/resource/set-PCY.jpg';
import setINV from '../images/resource/set-INV.jpg';
import setPLS from '../images/resource/set-PLS.jpg';
import setAPC from '../images/resource/set-APC.jpg';
import setODY from '../images/resource/set-ODY.jpg';
import setTOR from '../images/resource/set-TOR.jpg';
import setJUD from '../images/resource/set-JUD.jpg';
import setONS from '../images/resource/set-ONS.jpg';
import setLGN from '../images/resource/set-LGN.jpg';
import setSCG from '../images/resource/set-SCG.jpg';
import set8ED from '../images/resource/set-8ED.jpg';
import setMRD from '../images/resource/set-MRD.jpg';
import setDST from '../images/resource/set-DST.jpg';
import set5DN from '../images/resource/set-5DN.jpg';
import setCHK from '../images/resource/set-CHK.jpg';
import setBOK from '../images/resource/set-BOK.jpg';
import setSOK from '../images/resource/set-SOK.jpg';
import set9ED from '../images/resource/set-9ED.jpg';
import setRAV from '../images/resource/set-RAV.jpg';
import setGPT from '../images/resource/set-GPT.jpg';
import setDIS from '../images/resource/set-DIS.jpg';
import setCSP from '../images/resource/set-CSP.jpg';
import setTSP from '../images/resource/set-TSP.jpg';
import setPLC from '../images/resource/set-PLC.jpg';
import setFUT from '../images/resource/set-FUT.jpg';
import set10E from '../images/resource/set-10E.jpg';
import setME1 from '../images/resource/set-ME1.jpg';
import setLRW from '../images/resource/set-LRW.jpg';
import setMOR from '../images/resource/set-MOR.jpg';
import setSHM from '../images/resource/set-SHM.jpg';
import setEVE from '../images/resource/set-EVE.jpg';
import setME2 from '../images/resource/set-ME2.jpg';
import setALA from '../images/resource/set-ALA.jpg';
import setCON from '../images/resource/set-CON.jpg';
import setARB from '../images/resource/set-ARB.jpg';
import setM10 from '../images/resource/set-M10.jpg';
import setME3 from '../images/resource/set-ME3.jpg';
import setZEN from '../images/resource/set-ZEN.jpg';
import setWWK from '../images/resource/set-WWK.jpg';
import setROE from '../images/resource/set-ROE.jpg';
import setM11 from '../images/resource/set-M11.jpg';
import setSOM from '../images/resource/set-SOM.jpg';
import setME4 from '../images/resource/set-ME4.jpg';
import setMBS from '../images/resource/set-MBS.jpg';
import setNPH from '../images/resource/set-NPH.jpg';
import setM12 from '../images/resource/set-M12.jpg';
import setISD from '../images/resource/set-ISD.jpg';
import setDKA from '../images/resource/set-DKA.jpg';
import setAVR from '../images/resource/set-AVR.jpg';
import setM13 from '../images/resource/set-M13.jpg';
import setRTR from '../images/resource/set-RTR.jpg';
import setGTC from '../images/resource/set-GTC.jpg';
import setDGM from '../images/resource/set-DGM.jpg';
import setM14 from '../images/resource/set-M14.jpg';
import setTHS from '../images/resource/set-THS.jpg';
import setBNG from '../images/resource/set-BNG.jpg';
import setJOU from '../images/resource/set-JOU.jpg';
import setM15 from '../images/resource/set-M15.jpg';
import setKTK from '../images/resource/set-KTK.jpg';
import setFRF from '../images/resource/set-FRF.jpg';
import setDTK from '../images/resource/set-DTK.jpg';
import setORI from '../images/resource/set-ORI.jpg';
import setBFZ from '../images/resource/set-BFZ.jpg';
import setOGW from '../images/resource/set-OGW.jpg';
import setSOI from '../images/resource/set-SOI.jpg';
import setEMN from '../images/resource/set-EMN.jpg';
import setKLD from '../images/resource/set-KLD.jpg';
import setAER from '../images/resource/set-AER.jpg';
import setAKH from '../images/resource/set-AKH.jpg';
import setHOU from '../images/resource/set-HOU.jpg';
import setXLN from '../images/resource/set-XLN.jpg';
import setRIX from '../images/resource/set-RIX.jpg';
import setDOM from '../images/resource/set-DOM.jpg';
import setM19 from '../images/resource/set-M19.jpg';
import setGRN from '../images/resource/set-GRN.jpg';
import setRNA from '../images/resource/set-RNA.jpg';


// import symbolUnknown from '../images/resource/symbol-unknown.png'
import symbolUnknown from '../images/resource/symbol-unknown.png';
import symbolMIR from '../images/resource/symbol-MIR.png';
import symbolVIS from '../images/resource/symbol-VIS.png';
import symbolWTH from '../images/resource/symbol-WTH.png';
import symbolTMP from '../images/resource/symbol-TMP.png';
import symbolSTH from '../images/resource/symbol-STH.png';
import symbolEXO from '../images/resource/symbol-EXO.png';
import symbolUSG from '../images/resource/symbol-USG.png';
import symbolULG from '../images/resource/symbol-ULG.png';
import symbolUDS from '../images/resource/symbol-UDS.png';
import symbolMMQ from '../images/resource/symbol-MMQ.png';
import symbolNEM from '../images/resource/symbol-NEM.png';
import symbolPCY from '../images/resource/symbol-PCY.png';
import symbolINV from '../images/resource/symbol-INV.png';
import symbolPLS from '../images/resource/symbol-PLS.png';
import symbolAPC from '../images/resource/symbol-APC.png';
import symbolODY from '../images/resource/symbol-ODY.png';
import symbolTOR from '../images/resource/symbol-TOR.png';
import symbolJUD from '../images/resource/symbol-JUD.png';
import symbolONS from '../images/resource/symbol-ONS.png';
import symbolLGN from '../images/resource/symbol-LGN.png';
import symbolSCG from '../images/resource/symbol-SCG.png';
import symbol8ED from '../images/resource/symbol-8ED.png';
import symbolMRD from '../images/resource/symbol-MRD.png';
import symbolDST from '../images/resource/symbol-DST.png';
import symbol5DN from '../images/resource/symbol-5DN.png';
import symbolCHK from '../images/resource/symbol-CHK.png';
import symbolBOK from '../images/resource/symbol-BOK.png';
import symbolSOK from '../images/resource/symbol-SOK.png';
import symbolRAV from '../images/resource/symbol-RAV.png';
import symbolGPT from '../images/resource/symbol-GPT.png';
import symbolDIS from '../images/resource/symbol-DIS.png';
import symbolCSP from '../images/resource/symbol-CSP.png';
import symbolTSP from '../images/resource/symbol-TSP.png';
import symbolPLC from '../images/resource/symbol-PLC.png';
import symbolFUT from '../images/resource/symbol-FUT.png';
import symbol10E from '../images/resource/symbol-10E.png';
import symbolME1 from '../images/resource/symbol-ME1.png';
import symbolLRW from '../images/resource/symbol-LRW.png';
import symbolMOR from '../images/resource/symbol-MOR.png';
import symbolSHM from '../images/resource/symbol-SHM.png';
import symbolEVE from '../images/resource/symbol-EVE.png';
import symbolME2 from '../images/resource/symbol-ME2.png';
import symbolALA from '../images/resource/symbol-ALA.png';
import symbolCON from '../images/resource/symbol-CON.png';
import symbolARB from '../images/resource/symbol-ARB.png';
import symbolM10 from '../images/resource/symbol-M10.png';
import symbolME3 from '../images/resource/symbol-ME3.png';
import symbolZEN from '../images/resource/symbol-ZEN.png';
import symbolWWK from '../images/resource/symbol-WWK.png';
import symbolROE from '../images/resource/symbol-ROE.png';
import symbolM11 from '../images/resource/symbol-M11.png';
import symbolSOM from '../images/resource/symbol-SOM.png';
import symbolME4 from '../images/resource/symbol-ME4.png';
import symbolMBS from '../images/resource/symbol-MBS.png';
import symbolNPH from '../images/resource/symbol-NPH.png';
import symbolM12 from '../images/resource/symbol-M12.png';
import symbolISD from '../images/resource/symbol-ISD.png';
import symbolDKA from '../images/resource/symbol-DKA.png';
import symbolAVR from '../images/resource/symbol-AVR.png';
import symbolM13 from '../images/resource/symbol-M13.png';
import symbolRTR from '../images/resource/symbol-RTR.png';
import symbolGTC from '../images/resource/symbol-GTC.png';
import symbolDGM from '../images/resource/symbol-DGM.png';
import symbolM14 from '../images/resource/symbol-M14.png';
import symbolTHS from '../images/resource/symbol-THS.png';
import symbolBNG from '../images/resource/symbol-BNG.png';
import symbolJOU from '../images/resource/symbol-JOU.png';
import symbolM15 from '../images/resource/symbol-M15.png';
import symbolKTK from '../images/resource/symbol-KTK.png';
import symbolFRF from '../images/resource/symbol-FRF.png';
import symbolDTK from '../images/resource/symbol-DTK.png';
import symbolORI from '../images/resource/symbol-ORI.png';
import symbolBFZ from '../images/resource/symbol-BFZ.png';
import symbolOGW from '../images/resource/symbol-OGW.png';
import symbolSOI from '../images/resource/symbol-SOI.png';
import symbolEMN from '../images/resource/symbol-EMN.png';
import symbolKLD from '../images/resource/symbol-KLD.png';
import symbolAER from '../images/resource/symbol-AER.png';
import symbolAKH from '../images/resource/symbol-AKH.png';
import symbolHOU from '../images/resource/symbol-HOU.png';
import symbolXLN from '../images/resource/symbol-XLN.png';
import symbolRIX from '../images/resource/symbol-RIX.png';
import symbolDOM from '../images/resource/symbol-DOM.png';
import symbolM19 from '../images/resource/symbol-M19.png';
import symbolGRN from '../images/resource/symbol-GRN.png';
import symbolRNA from '../images/resource/symbol-RNA.png';
import symbol9ED from '../images/resource/symbol-9ED.png';


export const allSets = {
   MIR: {
      tImg: setMIR,
      symbol: symbolMIR,
      name: 'MIR',
      slug: 'Big Set',
      title: 'Big Set',
   },
   VIS: {
      tImg: setVIS,
      symbol: symbolVIS,
      name: 'VIS',
      slug: 'Big Set',
      title: 'Big Set',
   },
   WTH: {
      tImg: setWTH,
      symbol: symbolWTH,
      name: 'WTH',
      slug: 'Big Set',
      title: 'Big Set',
   },
   TMP: {
      tImg: setTMP,
      symbol: symbolTMP,
      name: 'TMP',
      slug: 'Big Set',
      title: 'Big Set',
   },
   STH: {
      tImg: setSTH,
      symbol: symbolSTH,
      name: 'STH',
      slug: 'Big Set',
      title: 'Big Set',
   },
   EXO: {
      tImg: setEXO,
      symbol: symbolEXO,
      name: 'EXO',
      slug: 'Big Set',
      title: 'Big Set',
   },
   USG: {
      tImg: setUSG,
      symbol: symbolUSG,
      name: 'USG',
      slug: 'Big Set',
      title: 'Big Set',
   },
   ULG: {
      tImg: setULG,
      symbol: symbolULG,
      name: 'ULG',
      slug: 'Big Set',
      title: 'Big Set',
   },
   UDS: {
      tImg: setUDS,
      symbol: symbolUDS,
      name: 'UDS',
      slug: 'Big Set',
      title: 'Big Set',
   },
   MMQ: {
      tImg: setMMQ,
      symbol: symbolMMQ,
      name: 'MMQ',
      slug: 'Big Set',
      title: 'Big Set',
   },
   NEM: {
      tImg: setNEM,
      symbol: symbolNEM,
      name: 'NEM',
      slug: 'Big Set',
      title: 'Big Set',
   },
   PCY: {
      tImg: setPCY,
      symbol: symbolPCY,
      name: 'PCY',
      slug: 'Big Set',
      title: 'Big Set',
   },
   INV: {
      tImg: setINV,
      symbol: symbolINV,
      name: 'INV',
      slug: 'Big Set',
      title: 'Big Set',
   },
   PLS: {
      tImg: setPLS,
      symbol: symbolPLS,
      name: 'PLS',
      slug: 'Big Set',
      title: 'Big Set',
   },
   APC: {
      tImg: setAPC,
      symbol: symbolAPC,
      name: 'APC',
      slug: 'Big Set',
      title: 'Big Set',
   },
   ODY: {
      tImg: setODY,
      symbol: symbolODY,
      name: 'ODY',
      slug: 'Big Set',
      title: 'Big Set',
   },
   TOR: {
      tImg: setTOR,
      symbol: symbolTOR,
      name: 'TOR',
      slug: 'Big Set',
      title: 'Big Set',
   },
   JUD: {
      tImg: setJUD,
      symbol: symbolJUD,
      name: 'JUD',
      slug: 'Small Set',
      title: 'Small Set',
   },
   ONS: {
      tImg: setONS,
      symbol: symbolONS,
      name: 'ONS',
      slug: 'Small Set',
      title: 'Small Set',
   },
   LGN: {
      tImg: setLGN,
      symbol: symbolLGN,
      name: 'LGN',
      slug: 'Small Set',
      title: 'Small Set',
   },
   SCG: {
      tImg: setSCG,
      symbol: symbolSCG,
      name: 'SCG',
      slug: 'Small Set',
      title: 'Small Set',
   },
   '8ED': {
      tImg: set8ED,
      symbol: symbol8ED,
      name: '8ED',
      slug: 'Small Set',
      title: 'Small Set',
   },
   MRD: {
      tImg: setMRD,
      symbol: symbolMRD,
      name: 'MRD',
      slug: 'Small Set',
      title: 'Small Set',
   },
   DST: {
      tImg: setDST,
      symbol: symbolDST,
      name: 'DST',
      slug: 'Small Set',
      title: 'Small Set',
   },
   '5DN': {
      tImg: set5DN,
      symbol: symbol5DN,
      name: '5DN',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'CHK': {
      tImg: setCHK,
      symbol: symbolCHK,
      name: 'CHK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'BOK': {
      tImg: setBOK,
      symbol: symbolBOK,
      name: 'BOK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'SOK': {
      tImg: setSOK,
      symbol: symbolSOK,
      name: 'SOK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   '9ED': {
      tImg: set9ED,
      symbol: symbol9ED,
      name: '9ED',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'RAV': {
      tImg: setRAV,
      symbol: symbolRAV,
      name: 'RAV',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'GPT': {
      tImg: setGPT,
      symbol: symbolGPT,
      name: 'GPT',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'DIS': {
      tImg: setDIS,
      symbol: symbolDIS,
      name: 'DIS',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'CSP': {
      tImg: setCSP,
      symbol: symbolCSP,
      name: 'CSP',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'TSP': {
      tImg: setTSP,
      symbol: symbolTSP,
      name: 'TSP',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'PLC': {
      tImg: setPLC,
      symbol: symbolPLC,
      name: 'PLC',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'FUT': {
      tImg: setFUT,
      symbol: symbolFUT,
      name: 'FUT',
      slug: 'Small Set',
      title: 'Small Set',
   },
   '10E': {
      tImg: set10E,
      symbol: symbol10E,
      name: '10E',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ME1': {
      tImg: setME1,
      symbol: symbolME1,
      name: 'ME1',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'LRW': {
      tImg: setLRW,
      symbol: symbolLRW,
      name: 'LRW',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'MOR': {
      tImg: setMOR,
      symbol: symbolMOR,
      name: 'MOR',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'SHM': {
      tImg: setSHM,
      symbol: symbolSHM,
      name: 'SHM',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'EVE': {
      tImg: setEVE,
      symbol: symbolEVE,
      name: 'EVE',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ME2': {
      tImg: setME2,
      symbol: symbolME2,
      name: 'ME2',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ALA': {
      tImg: setALA,
      symbol: symbolALA,
      name: 'ALA',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'CON': {
      tImg: setCON,
      symbol: symbolCON,
      name: 'CON',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ARB': {
      tImg: setARB,
      symbol: symbolARB,
      name: 'ARB',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'M10': {
      tImg: setM10,
      symbol: symbolM10,
      name: 'M10',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ME3': {
      tImg: setME3,
      symbol: symbolME3,
      name: 'ME3',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ZEN': {
      tImg: setZEN,
      symbol: symbolZEN,
      name: 'ZEN',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'WWK': {
      tImg: setWWK,
      symbol: symbolWWK,
      name: 'WWK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   ROE: {
      tImg: setROE,
      symbol: symbolROE,
      name: 'ROE',
      slug: 'Big Set',
      title: 'Big Set',
   },
   'M11': {
      tImg: setM11,
      symbol: symbolM11,
      name: 'M11',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'SOM': {
      tImg: setSOM,
      symbol: symbolSOM,
      name: 'SOM',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ME4': {
      tImg: setME4,
      symbol: symbolME4,
      name: 'ME4',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'MBS': {
      tImg: setMBS,
      symbol: symbolMBS,
      name: 'MBS',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'NPH': {
      tImg: setNPH,
      symbol: symbolNPH,
      name: 'NPH',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'M12': {
      tImg: setM12,
      symbol: symbolM12,
      name: 'M12',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ISD': {
      tImg: setISD,
      symbol: symbolISD,
      name: 'ISD',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'DKA': {
      tImg: setDKA,
      symbol: symbolDKA,
      name: 'DKA',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'AVR': {
      tImg: setAVR,
      symbol: symbolAVR,
      name: 'AVR',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'M13': {
      tImg: setM13,
      symbol: symbolM13,
      name: 'M13',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'RTR': {
      tImg: setRTR,
      symbol: symbolRTR,
      name: 'RTR',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'GTC': {
      tImg: setGTC,
      symbol: symbolGTC,
      name: 'GTC',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'DGM': {
      tImg: setDGM,
      symbol: symbolDGM,
      name: 'DGM',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'M14': {
      tImg: setM14,
      symbol: symbolM14,
      name: 'M14',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'THS': {
      tImg: setTHS,
      symbol: symbolTHS,
      name: 'THS',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'BNG': {
      tImg: setBNG,
      symbol: symbolBNG,
      name: 'BNG',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'JOU': {
      tImg: setJOU,
      symbol: symbolJOU,
      name: 'JOU',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'M15': {
      tImg: setM15,
      symbol: symbolM15,
      name: 'M15',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'KTK': {
      tImg: setKTK,
      symbol: symbolKTK,
      name: 'KTK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'FRF': {
      tImg: setFRF,
      symbol: symbolFRF,
      name: 'FRF',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'DTK': {
      tImg: setDTK,
      symbol: symbolDTK,
      name: 'DTK',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'ORI': {
      tImg: setORI,
      symbol: symbolORI,
      name: 'ORI',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'BFZ': {
      tImg: setBFZ,
      symbol: symbolBFZ,
      name: 'BFZ',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'OGW': {
      tImg: setOGW,
      symbol: symbolOGW,
      name: 'OGW',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'SOI': {
      tImg: setSOI,
      symbol: symbolSOI,
      name: 'SOI',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'EMN': {
      tImg: setEMN,
      symbol: symbolEMN,
      name: 'EMN',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'KLD': {
      tImg: setKLD,
      symbol: symbolKLD,
      name: 'KLD',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'AER': {
      tImg: setAER,
      symbol: symbolAER,
      name: 'AER',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'AKH': {
      tImg: setAKH,
      symbol: symbolAKH,
      name: 'AKH',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'HOU': {
      tImg: setHOU,
      symbol: symbolHOU,
      name: 'HOU',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'XLN': {
      tImg: setXLN,
      symbol: symbolXLN,
      name: 'XLN',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'RIX': {
      tImg: setRIX,
      symbol: symbolRIX,
      name: 'RIX',
      slug: 'Small Set',
      title: 'Small Set',
   },
   'DOM': {
      tImg: setDOM,
      symbol: symbolDOM,
      name: 'DOM',
      slug: 'Big Set',
      title: 'Big Set',
   },
   'M19': {
      tImg: setM19,
      symbol: symbolM19,
      name: 'M19',
      slug: 'Big Set',
      title: 'Big Set',
   },
   'GRN': {
      tImg: setGRN,
      symbol: symbolGRN,
      name: 'GRN',
      slug: 'Core Set',
      title: 'Core Set',
   },
   'RNA': {
      tImg: setRNA,
      symbol: symbolRNA,
      name: 'RNA',
      slug: 'Core Set',
      title: 'Core Set',
   },
};

export const emptySets = [
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Big Set',
      title: 'Big Set',
   },
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Core Set',
      title: 'Core Set',
   },
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Small Set',
      title: 'Small Set',
   },
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Big Set',
      title: 'Big Set',
   },
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Small Set',
      title: 'Small Set',
   },
   {
      tImg: setUnknown,
      symbol: symbolUnknown,
      name: '???',
      slug: 'Small Set',
      title: 'Small Set',
   },
];

const setHistory = [
   [allSets['ONS'], allSets['10E'], allSets['DTK'], allSets['PLC'], allSets['CON'], allSets['OGW']]
];

const currentSets = [...setHistory[0]];
// const currentSets = [...emptySets];

export default currentSets;